import { extendTheme } from "@chakra-ui/react"
import { withProse } from "@nikolovlazar/chakra-ui-prose"
import Wrapper from "../../components/Wrapper"

const breakpoints = {
  sm: "30em",
  smm: "36em",
  md: "48em",
  lg: "62em",
  xl: "88em",
  "2xl": "96em",
}
const space = {
  px: "1px",
  0.5: "0.125rem",
  1: "0.25rem",
  1.5: "0.375rem",
  2: "0.5rem",
  2.5: "0.625rem",
  3: "0.75rem",
  3.5: "0.875rem",
  4: "1rem",
  5: "1.25rem",
  6: "1.5rem",
  7: "1.75rem",
  8: "2rem",
  9: "2.25rem",
  10: "2.5rem",
  12: "3rem",
  14: "3.5rem",
  16: "4rem",
  20: "5rem",
  24: "6rem",
  28: "7rem",
  32: "8rem",
  36: "9rem",
  40: "10rem",
  44: "11rem",
  48: "12rem",
  52: "13rem",
  56: "14rem",
  60: "15rem",
  64: "16rem",
  72: "18rem",
  80: "20rem",
  96: "24rem",
}

const theme = {
  breakpoints,
  space,
  components: {
    Wrapper,
    Heading: {
      variants: {
        h1: {
          lineHeight: 1.1,
          fontWeight: 600,
          fontSize: () => ({ base: "4xl", sm: "5xl", lg: "6xl" }),
        },
        h2: {
          fontSize: () => ({ base: "3xl", sm: "4xl", lg: "5xl" }),
        },
        h3: {
          fontSize: () => ({ base: "xl", sm: "2xl", lg: "3xl" }),
        },
      },
    },
    Container: {
      baseStyle: {
        px: { base: 6, md: 10, "2xl": 0 },
      },
    },
  },
  sizes: {
    container: { ...breakpoints },
  },
  config: {
    initialColorMode: "dark",
    useSystemColorMode: false,
  },

  fonts: {
    body: "Roboto, system-ui, sans-serif",
    heading: "Roboto, system-ui, sans-serif",
    mono: "Menlo, monospace",
  },
  colors: {
    primary: "white",
    lightgray: "#eaeaf7",
    gradient: {
      light: "#6227FF",
      dark: "#5323D3",
    },
    orange: {
      light: "#FC8F8F",
      dark: "#F43E80",
    },
    ondark: {
      white: "#d9d8e7",
      bright: "#A09CCE",
      light: "#8b89a7",
      gray: "#696689",
      purple: "#000120",
    },
    purple: {
      dark: "#0D0A33",
    },
  },
  styles: {
    global: (props) => ({
      "html, body": {
        backgroundColor: "purple.dark",
        color: "purple.dark",
        lineHeight: "tall",
      },
      a: {
        color: props.colorMode === "dark" ? "teal.300" : "teal.500",
      },
    }),
  },
}

const proseTheme = {
  baseStyle: {
    p: {
      fontSize: "lg",
      lineHeight: "tall",
    },
  },
}

export default extendTheme(theme, withProse(proseTheme))
