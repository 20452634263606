import { defineStyleConfig } from "@chakra-ui/react"

const Wrapper = defineStyleConfig({
  // The styles all button have in common
  // baseStyle: {},
  // The default size and variant values
  // defaultProps: {},
})

export default Wrapper
