exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-archives-default-js": () => import("./../../../src/templates/archives/default.js" /* webpackChunkName: "component---src-templates-archives-default-js" */),
  "component---src-templates-pages-about-js": () => import("./../../../src/templates/pages/About.js" /* webpackChunkName: "component---src-templates-pages-about-js" */),
  "component---src-templates-pages-contact-js": () => import("./../../../src/templates/pages/Contact.js" /* webpackChunkName: "component---src-templates-pages-contact-js" */),
  "component---src-templates-pages-default-js": () => import("./../../../src/templates/pages/Default.js" /* webpackChunkName: "component---src-templates-pages-default-js" */),
  "component---src-templates-pages-home-page-js": () => import("./../../../src/templates/pages/HomePage.js" /* webpackChunkName: "component---src-templates-pages-home-page-js" */),
  "component---src-templates-pages-services-js": () => import("./../../../src/templates/pages/Services.js" /* webpackChunkName: "component---src-templates-pages-services-js" */),
  "component---src-templates-pages-use-cases-js": () => import("./../../../src/templates/pages/UseCases.js" /* webpackChunkName: "component---src-templates-pages-use-cases-js" */),
  "component---src-templates-types-post-js": () => import("./../../../src/templates/types/post.js" /* webpackChunkName: "component---src-templates-types-post-js" */)
}

