import "./src/assets/styles/fonts.css"

// eslint-disable-next-line import/prefer-default-export
export const onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV !== "production") {
    return null
  }

  const pagePath = location
    ? location.pathname + location.search + location.hash
    : undefined
  setTimeout(() => {
    if (typeof window.gtag === "function") {
      window.gtag("event", "page_view", { page_path: pagePath })
    }
  }, 100)

  // Check for location so build does not fail
  if (location && location.hash) {
    const item = document.querySelector(`${location.hash}`).offsetTop

    window.scrollTo({
      top: item,
      behavior: "auto",
    })
  }
  return true
}
